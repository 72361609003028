import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en } from './lang/en/en';
import { ru } from './lang/ru/ru';

const BASE_PRICE = +(process.env.REACT_APP_BASE_PRICE || 1190);
const BASE_PRICE_EURO = +(process.env.REACT_APP_BASE_PRICE_EURO || 14);

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: 'ru',
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en,
      ru
    },
  });

export default i18n;
