import { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { Routes, Route, useNavigationType, useNavigate, useLocation } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { deleteCookie, getCookie, setCookie } from './utils/cookies';
import { useAppDispatch } from './store/store';
import { saveToken, saveUser, userSelector } from './store/slices/user';
import { useLazyGetPersonalInfoQuery, useVerifyEmailMutation } from './store/api/userService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Modals from './components/Modals';
import CookiesBanner from './components/CookiesBanner/CookiesBanner';
import { retry } from './utils/componentLoader';

import './i18next';

const Home = lazy(() => retry(() => import('./pages/Home')));
const SearchResult = lazy(() => retry(() => import('./pages/SearchResult')));
const OrderDetailsPage = lazy(() => retry(() => import('./pages/LK/OrderDetailsPage/OrderDetailsPage')));
const OrdersPage = lazy(() => retry(() => import('./pages/LK/OrdersPage/OrdersPage')));
const PassengersPage = lazy(() => retry(() => import('./pages/LK/PassengersPage/PassengersPage')));
const AddPassengerPage = lazy(() => retry(() => import('./pages/LK/AddPassengerPage/AddPassengerPage')));
const PersonalPage = lazy(() => retry(() => import('./pages/LK/PersonalPage/PersonalPage')));
const ProfilePage = lazy( () => retry( () => import('./pages/LK/ProfilePage') ) );
const BookingPage = lazy(() => retry(() => import('./pages/BookingPage')));
const BuyPage = lazy(() => retry(() => import('./pages/BuyPage')));
const NotFound = lazy(() => retry(() => import('./pages/NotFound/NotFound')));
const BadGateway = lazy(() => retry(() => import('./pages/BadGateway/BadGateway')));
const PayStub = lazy(() => retry(() => import('./pages/PayStub')));
const OrderPaymentSuccess = lazy(() => retry(() => import('./pages/LK/OrderPaymentSuccess/OrderPaymentSuccess')));
const OrderPaymentFail = lazy(() => retry(() => import('./pages/LK/OrderPaymentFail/OrderPaymentFail')));
const SafetyReturnPolicy = lazy(() => retry(() => import('./pages/Policies/SafetyReturnPolicy/SafetyReturnPolicy')));
const ConfedentialPolicy = lazy(() => retry(() => import('./pages/Policies/ConfedentialPolicy/ConfedentialPolicy')));
const Rules = lazy(() => retry(() => import('./pages/Policies/Rules/Rules')));
const BlogPosts = lazy(() => retry(() => import('./pages/Blog/BlogPosts/BlogPosts')));
const BlogPost = lazy(() => retry(() => import('./pages/Blog/BlogPost/BlogPost')));
const Tariffs = lazy(() => retry(() => import('./pages/Tariffs/Tariffs')));
const Help = lazy(() => retry(() => import('./pages/Help/Help')));
const Feedback = lazy(() => retry(() => import('./pages/Feedback/Feedback')));
const PartnershipMainPage = lazy(() => retry(() => import('./pages/Partnership/Partnership')));
const PartnershipPage = lazy(() => retry(() => import('./pages/LK/PartnershipPage/PartnershipPage')));
const PartnershipLinkDetails = lazy(() =>
  retry(() => import('./components/Partnership/PartnershipLinkDetails/PartnershipLinkDetails'))
);
import { partnerApi } from './store/api/partnerService';
import { useSelector } from 'react-redux';
import { useGetScriptsQuery } from './store/api/settingsService';
import { b64_to_utf8 } from './utils/adapters';
import { setModalText, updateModal } from './store/slices/modal';
import {useTranslation} from "react-i18next";
import type {
  i18n,
} from 'i18next';
import { useLangNavigate } from './utils/langNavigate';
import { useLangRoute } from './utils/useLangRoute';
import { routes } from './constants/routes';
import { getLangMetaData } from './utils/getLangMetaData';
import useIsMobile from './utils/useIsMobile';

const REACT_APP_REF_LINK_MAX_AGE = Number(process.env.REACT_APP_REF_LINK_MAX_AGE) || 3600 * 24 * 7;

function App({ i18n }: { i18n: i18n }) {
  const { t } = useTranslation();

  const langNavigate = useLangNavigate();

  const { langRoute } = useLangRoute();

  useEffect(() => {
    if (langRoute === '/en') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('ru');
    }
  }, [langRoute]);

  const registerText = {
    header: t('app.createPassword.header'),
    text: t('app.createPassword.text'),
    button: t('app.createPassword.button'),
  };

  const recoverText = {
    header: t('app.changePassword.header'),
    text: t('app.changePassword.text'),
    button: t('app.changePassword.button'),
  };

  const errorText = {
    header: t('app.invalidLink.header'),
    text: t('app.invalidLink.text'),
    button: '',
    isError: true,
  };

  const href = useLocation();
  const pathname = href.pathname;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const action = useNavigationType();
  const { token } = useSelector(userSelector);
  const isMobile = useIsMobile(1025);
  const [getPersonalInfo] = useLazyGetPersonalInfoQuery();
  const [setSubscriber] = partnerApi.useSetSubscriberMutation();
  const { data: scriptsData } = useGetScriptsQuery({});
  const [verifyEmail] = useVerifyEmailMutation();

  const verifyEmailFromData = (email: string, code: string) => {
    const isRegister = pathname.includes('register');
    const text = isRegister ? registerText : recoverText;
    if (!code || !email) {
      dispatch(setModalText(errorText));
      dispatch(updateModal({ success: true }));
      return;
    }
    verifyEmail({ email, code })
      .unwrap()
      .then(data => {
        if (!data || !data.token || !data.status) {
          dispatch(setModalText(errorText));
          dispatch(updateModal({ success: true }));
          langNavigate(routes.home, { replace: true });
          return;
        }
        localStorage.setItem('temp_token', data.token);
        dispatch(setModalText(text));
        dispatch(updateModal({ createPassword: true }));
        langNavigate(routes.home, { replace: true });
      })
      .catch(() => {
        dispatch(setModalText(errorText));
        dispatch(updateModal({ success: true }));
        langNavigate(routes.home, { replace: true });
      });
  };

  const processLink = useCallback(() => {
    if (pathname.startsWith('/ref_link/')) {
      const link = pathname.split('/')?.[2];
      if (!link) return;
      setSubscriber({ token, link })
        .unwrap()
        .then(data => {
          const refcode = data.refcode;
          const maxAge = data['max-age'] || REACT_APP_REF_LINK_MAX_AGE;
          if (!refcode) return;
          setCookie('refcode', refcode, maxAge);
        })
        .finally(() => {
          langNavigate(routes.home);
        });
    }

    if (pathname.startsWith('/register')) {
      if (href.search.indexOf('?code=') > -1 && href.search.indexOf('&email=') > -1) {
        const verify = href.search.split('?code=')[1].trim();
        const separate = verify.split('&email=');
        const code = separate[0].trim();
        const email = separate[1].trim();
        verifyEmailFromData(email, code);
      }
    }

    if (pathname.indexOf('recover') > -1) {
      if (href.search.indexOf('verify=') > -1) {
        const verify = href.search.split('verify=')[1].trim();
        if (verify.length > 0) {
          const verifyData = atob(verify).split('|');
          if (verifyData?.length > 0) {
            const code = verifyData[0].split('=')[1].trim();
            const email = verifyData[1].split('=')[1].trim();
            verifyEmailFromData(email, code);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    processLink();
  }, []);

  useEffect(() => {
    if (action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [action]);

  useEffect(() => {
    const logout = () => {
      deleteCookie('token');
      //if (href.pathname.match(/^\/lk/) || href.pathname.search(/code=/)) navigate('/');
      if (
        href.pathname.match(/^\/lk/) &&
        !(href.pathname.indexOf('/orders/success') > 0 || href.pathname.indexOf('/orders/fail') > 0)
      ) {
        langNavigate(routes.home);
      }
    };
    const token = getCookie('token');
    if (!token) return logout();
    dispatch(saveToken({ token }));
    getPersonalInfo({ token })
      .unwrap()
      .then(user => {
        dispatch(saveUser({ user }));
      })
      .catch(() => logout());

    if ( pathname === langRoute + routes.lk && !isMobile ){
      langNavigate(routes.lk_profile);
    }

  }, []);

  const initializeScript = (node: HTMLElement, code?: string) => {
    const script = document.createElement('div');
    script.innerHTML = code ? b64_to_utf8(code).trim() : '';
    if (script.childNodes && script.childNodes.length > 0) {
      script.childNodes.forEach(element => {
        node.append(element);
        if (element.nodeName.toLowerCase() === 'script' && typeof element.textContent === 'string') {
          eval(element.textContent.trim());
        }
      });
    }
  };

  useEffect(() => {
    if (scriptsData && scriptsData.data) {
      initializeScript(document.head, scriptsData.data.header); // scripts header
      initializeScript(document.body, scriptsData.data.footer); // scripts footer
    }
  }, [scriptsData]);

  return (
    <HelmetProvider>
      <ToastContainer />
      <Modals />
      <CookiesBanner />
      <Routes>
        <Route
          path={langRoute + routes.home}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.home) }
              </Helmet>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.register}
          element={
            <Suspense fallback={null}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={ `${langRoute}"/ref_link/:hash"` }
          element={
            <Suspense fallback={null}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.passwordRecover}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.passwordRecover) }
              </Helmet>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.search}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.search) }
              </Helmet>
              <SearchResult />
            </Suspense>
          }
        />
        <Route
          path={langRoute +  routes.booking}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.booking) }
              </Helmet>
              <BookingPage />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.buying}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.buying) }
              </Helmet>
              <BuyPage />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.lk}
          element={
            <Suspense fallback={null}>
              <ProfilePage />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.lk_profile}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.lk_profile) }
              </Helmet>
              <PersonalPage />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.lk_orders}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.lk_orders) }
              </Helmet>
              <OrdersPage />
            </Suspense>
          }
        />
        <Route
          path={ langRoute + routes.lkOrdersSuccessId }
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.lkOrdersSuccessId) }
              </Helmet>
              <OrderPaymentSuccess />
            </Suspense>
          }
        />
        <Route
          path={ langRoute + routes.lkOrdersFailId }
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.lkOrdersFailId) }
              </Helmet>
              <OrderPaymentFail />
            </Suspense>
          }
        />
        <Route
          path={ langRoute + routes.lkOrdersId }
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.lkOrdersId) }
              </Helmet>
              <OrderDetailsPage />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.lk_passengers}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.lk_passengers) }
              </Helmet>
              <PassengersPage />
            </Suspense>
          }
        />
        <Route
          path={ langRoute + routes.lkPassengersId }
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.lkPassengersId) }
              </Helmet>
              <AddPassengerPage />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.lk_partnership}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.lk_partnership) }
              </Helmet>
              <PartnershipPage />
            </Suspense>
          }
        />
        <Route
          path={ langRoute + routes.lkPartnershipId }
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.lkPartnershipId) }
              </Helmet>
              <PartnershipLinkDetails />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.safety_return_policy}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.safety_return_policy) }
              </Helmet>
              <SafetyReturnPolicy />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.confedential_policy}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.confedential_policy) }
              </Helmet>
              <ConfedentialPolicy />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.blog}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.blog) }
              </Helmet>
              <BlogPosts />
            </Suspense>
          }
        />
        <Route
          path={ langRoute + routes.blogId }
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.blogId) }
              </Helmet>
              <BlogPost />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.rules}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.rules) }
              </Helmet>
              <Rules />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.tariff}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.fareAndPriceForKupiTripBooking')}</title>
                <meta
                  name="description"
                  content="Цена тарифа авиабилетов для визы без оплаты билета. Стоимость тарифа для брони 1190 рублей. Официальная бронь. Тариф купитрип"
                />
                { getLangMetaData(routes.tariff) }
              </Helmet>
              <Tariffs />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.help}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.howMuchDoKupiTripServicesCost')}</title>
                <meta
                  name="description"
                  content="Популярные вопросы к сервису купитрип. Сколько стоят услуги купитрип. Можно ли вернуть бронь купитрип? Официальная бронь."
                />
                { getLangMetaData(routes.help) }
              </Helmet>
              <Help />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.feedback}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.kupiTripBookingReviews')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.kupiTripBookingReviews')}
                />
                { getLangMetaData(routes.feedback) }
              </Helmet>
              <Feedback />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.partnership}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.partnership) }
              </Helmet>
              <PartnershipMainPage />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.pay_stub}
          element={
            <Suspense fallback={null}>
              <PayStub />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.unknownPage}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.unknownPage) }
              </Helmet>
              <NotFound />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.badGateway}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.badGateway) }
              </Helmet>
              <BadGateway />
            </Suspense>
          }
        />
        <Route
          path={langRoute + routes.any}
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>{t('titles.bookingAirlineTicketsForVisa')}</title>
                <meta
                  name="description"
                  content={t('metaDescriptions.bookingAirlineTicketsForVisa')}
                />
                { getLangMetaData(routes.any) }
              </Helmet>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </HelmetProvider>
  );
}
export default App;
